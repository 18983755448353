import React from 'react';
import { Create, SimpleForm, TextInput, required, DateInput, ReferenceInput, SelectInput } from 'react-admin';
import { FormToolbar } from "../../common/FormToolbar";
import { BooleanInput } from 'react-admin';

export const ActivityCreate = props => {
    let defaultValues = {
        is_show_qty: false,
    };
    return (
        <React.Fragment>
            <Create label="Edit Category" {...props}>
                <SimpleForm redirect="list" initialValues={defaultValues}
                    toolbar={<FormToolbar {...props} />}
                    variant="outlined">
                  
                    <TextInput source="name" fullWidth={true} validate={[required()]} />
                  
                </SimpleForm>
            </Create>
        </React.Fragment>
    );
}