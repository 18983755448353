import React from 'react';
import { Route } from 'react-router-dom';
import Configuration from './configuration/Configuration';
import { ChangePassword } from './screens/changepassword/ChangePassword';
import {WebAbout} from "./frontend/About";
import {WebHome} from "./frontend/Home";
import {WebTeam} from "./frontend/Team";
import WebDashboard from "./frontend/Dashboard";
import WebDocument from "./frontend/Documents";
import {WebContact} from "./frontend/Contact";
import {WebRegister} from "./frontend/Register";
import QuoteReport from "./screens/quote/QuoteReport";
import { Login } from "./layout";


export default [
  <Route exact path='/configuration' render={() => <Configuration />} />,
  <Route exact path="/about" render={() => <WebAbout />} noLayout />,
  <Route exact path="/home" render={() => <WebHome />} noLayout />,
  <Route exact path="/tenders" render={() => <WebDashboard />} noLayout />,
  <Route exact path="/documentlist" render={() => <WebDocument />} noLayout />,
  
  <Route exact path="/team" render={() => <WebTeam />} noLayout />,
  <Route exact path="/contact" render={() => <WebContact />} noLayout />,
  <Route exact path="/backoffice" render={() => <Login />} noLayout />,
  <Route exact path="/register" render={() => <WebRegister />} noLayout />,
  <Route exact path="/quotereport/:id" render={() => <QuoteReport />} />,

  <Route
    exact
    path='/changepassword'
    render={(props) => <ChangePassword {...props} />}
  />,
];
