import * as http from "./http";
import { APIUrl } from "../env";
export const isDemoSite = false;

export const innerUploadFile = (field, data) => {
  const formData = new FormData();
  formData.append(field, data.rawFile);
  return fetch(`${APIUrl}upload`, {
    method: "POST",
    body: formData,
  })
    .then((response) => response.json())
    .then((responseJson) => {
      return responseJson;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getVideoId = (content_url) => {
  var aData = content_url.split("/");
  var index = aData.length - 1;
  return aData[index];
};
export const VideoPatch = (url, token, model) => {
  let headers = new Headers();
  headers.append("Authorization", "Bearer " + token);
  headers.append("Content-Type", "application/json");
  return fetch(url, {
    method: "PATCH",
    body: JSON.stringify(model),
    headers,
  }).then((r) => {
    if (r.status == 409) {
      return r.json();
    }
    if (!r.ok) {
      throw Error(r.statusText);
    }
    return r.json();
  });
};

export const updateVideoMeta = (video_id, token, title, description) => {
  let api = "https://api.vimeo.com/videos/" + video_id;
  let data = {
    name: title,
    description: description,
  };
  return VideoPatch(api, token, data).then((response) => {
    return response;
  });
};
export const registerBidder = (user) => {
  let api = APIUrl + "user/register_bidder";
  return http.post(api, user).then((response) => {
    return response;
  });
};
export const approveContent = (contentId, approvalFlag) => {
  let api = APIUrl + "content/approve_disapprove_content";
  let data = {
    id: contentId,
    status: approvalFlag,
  };
  return http.post(api, data).then((response) => {
    return response;
  });
};
export const loginUser = (user) => {
  let api = APIUrl + "login/login";
  return http.post(api, user).then((response) => {
    return response;
  });
};

export const StudentTracking = (content_id) => {
  let api = APIUrl + "studenttracking";
  let data = {
    content_id: content_id,
  };
  return http.post(api, data).then((response) => {
    return response;
  });
};

export const ContentReadStatus = (content_id) => {
  let api = APIUrl + "contentreadstatus";
  let data = {
    content_id: content_id,
  };
  return http.post(api, data).then((response) => {
    return response;
  });
};

export const getOrgData = () => {
  let api = APIUrl + "org/1";
  return http.get(api).then((response) => {
    return response;
  });
};

export const getStudentAssignment = () => {
  let api = APIUrl + "dashboard/upcoming_assignments";
  return http.get(api).then((response) => {
    return response;
  });
};

export const loginUserByToken = (token) => {
  let api = APIUrl + "userById";
  let user:any = {
    token: token,
    StartIndex: 0,
    PageSize: 10,
  };
  return http.post(api, user).then((response) => {
    return response;
  });
};

export const submit_quote = (data) => {
  let api = APIUrl + "quote_submission/submit_quote";
  return http.post(api, data).then((response) => {
    return response;
  });
};

export const submit_feedback = (data) => {
  let api = APIUrl + "quote/feedback";
  return http.post(api, data).then((response) => {
    return response;
  });
};

export const is_quote_submitted = (user_id, quote_id) => {
  let data:any= {user_id, quote_id};
  let api = APIUrl + "quote_submission/is_quote_submitted";
  return http.post(api, data).then((response) => {
    return response;
  });
};

export const user_quote_submissions = (user_id, quote_id) => {
  let data:any= {user_id, quote_id};
  let api = APIUrl + "quote_submission/user_quote_submissions";
  return http.post(api, data).then((response) => {
    return response;
  });
};



export const quote_submissions = (quote_id) => {
  let api = `${APIUrl}quote/quote_submissions/${quote_id}`;
  return http.get(api).then((response) => {
    return response;
  });
};

export const deleteRecord = (path, id) => {
  let api = APIUrl + path + "/" + id;
  return http.del(api).then((response) => {
    return response;
  });
};


export const addEntiyRecord = (resource, data) => {
  let api = APIUrl + resource;
  return http.post(api, data).then((response) => {
    return response;
  });
};

export const ChangePassword = (data) => {
  let api = APIUrl + "user/update_password";
  return http.post(api, data).then((response) => {
    return response;
  });
};

export const reviewAssignment = (data) => {
  let api = APIUrl + "assignment/review_assignment";
  return http.post(api, data).then((response) => {
    return response;
  });
};

export const test_assignment_check = (data) => {
  let api = APIUrl + "tl_assignment_check";
  return http.post(api, data).then((response) => {
    return response;
  });
};

export const getSubjectsForStudent = () => {
  let api = APIUrl + "subject/get_subjects_for_logged_user";
  return http.get(api).then((response) => {
    return response;
  });
};

export const getContentPageForSubject = (subjectId) => {
  let api = APIUrl + `content/get_content_page_for_subject/${subjectId}`;
  return http.get(api).then((response) => {
    return response;
  });
};

export const getAccountToken = (accountId) => {
  let api = APIUrl + `account/get_account_token`;
  let data = { accountId: accountId };
  return http.post(api, data).then((response) => {
    return response;
  });
};

export const getSectionsForClass = (classId) => {
  //let api = APIUrl + `section/${classId}`;
  var filter = { "class_id=": classId };
  const filterString = JSON.stringify(filter);
  let api = `${APIUrl}/section?filter=${filterString}`;
  return http.get(api).then((response) => {
    return response;
  });
};

export const getSubjects = () => {
  let api = `${APIUrl}/subject?pagesize=500`;
  return http.get(api).then((response) => {
    return response;
  });
};


export const quotes_for_today = () => {
  let api = APIUrl + `quote/quotes_for_today`;
  
  return http.get(api).then((response) => {
    return response;
  });
};
export const quote_details = (id) => {
  let api = APIUrl + `quote/quote_details`;
  let data:any = {id};
  return http.post(api, data).then((response) => {
    return response;
  });
};

export const get_quote = (id) => {
  let api = APIUrl + `quote/` + id;
  return http.get(api).then((response) => {
    return response;
  });
};

export const getAllDocuments = () => {
  let api = APIUrl + `document`;
  return http.get(api).then((response) => {
    return response;
  });
};
export const getAllDocumentForDocGroup = (id:any) => {
  var filter = { "doc_group_id=": id };
  const filterString = JSON.stringify(filter);
  let api = `${APIUrl}/document?filter=${filterString}`;
  return http.get(api).then((response) => {
    return response;
  });
};
export const getAllDocumentGroups = () => {
  let api = APIUrl + `docgroup/with_documents`;
  return http.get(api).then((response) => {
    return response;
  });
};
export const getAllTeam = () => {
  let api = APIUrl + `teammember/active`;
  return http.get(api).then((response) => {
    return response;
  });
};