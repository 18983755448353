import React, { useState, useEffect } from "react";

import "./style/bootstrap.min.css";
import "./style/style.css";
import carousel from "./images/carousel-1.jpg";
import about from "./images/about-1.jpg";
import about1 from "./images/about-2.jpg";
import { WebHeader } from "./common/Header";
import { WebFooter } from "./common/Footer";
import * as apiClient from "../common/apiClient";
import { toast } from "react-toastify";
import CircularProgress from '@material-ui/core/CircularProgress';
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';

export const WebContact = (props) => {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [captcha, setCaptcha] = useState("");
  const [loading, setLoading] = useState(false);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const submitFeedback = () => {
    if (name == "" || name.length < 3) {
      toast.error("Please enter your name");
      return;
    }
    if (email == "" || email.length < 3) {
      toast.error("Please enter your Email");
      return;
    }
    if (!validateEmail(email)) {
      toast.error("Please provide the valid email address");
      return;
    }
    if (subject == "" || subject.length < 3) {
      toast.error("Please enter subject");
      return;
    }
    if (message == "" || message.length < 5) {
      toast.error("Please enter message");
      return;
    }

    if (captcha == "") {
      toast.error("Please enter Captcha");
      return;
    }

    if (validateCaptcha(captcha) == false) {
      toast.error("Captcha not  valid");
      return;
    }

    let data = {
      name,
      email,
      subject,
      message
    }
    setLoading(true);
    apiClient.submit_feedback(data).then(res => {
      setLoading(false);
      if (res.status == "success") {
        setName("");
        setEmail("");
        setSubject("");
        setMessage("");
        setCaptcha("");
        toast.info("Your message sent successfully to MCBOA. Thank for your feedback")
      } else {
        toast.error("Error submitting the feedback");
        setLoading(false);
      }

    }).catch(err => {
      toast.error("Error submitting the feedback");
      setLoading(false);
    });

  }

  useEffect(() => {
    loadCaptchaEnginge(6);
  }, []);

  return (
    <>
      <WebHeader />
      <div className="page">
        <div className="container-xxl py-5">
          <div className="container">
            <div className="row g-5 justify-content-center mb-5">
              <div
                className="col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="bg-light text-center h-100 p-5">
                  <div
                    className="btn-square bg-white rounded-circle mx-auto mb-4"
                    style={{ width: "90px", height: "90px" }}
                  >
                    <i className="fa fa-phone-alt fa-2x text-primary"></i>
                  </div>
                  <h4 className="mb-3">Phone Number</h4>
                  <p className="mb-2"><a href="tel:919910261081">+91-9910261081</a></p>
                  <a className="btn btn-primary px-4" href="tel:919910261081">
                    Call Now <i className="fa fa-arrow-right ms-2"></i>
                  </a>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.3s"
              >
                <div className="bg-light text-center h-100 p-5">
                  <div
                    className="btn-square bg-white rounded-circle mx-auto mb-4"
                    style={{ width: "90px", height: "90px" }}
                  >
                    <i className="fa fa-envelope-open fa-2x text-primary"></i>
                  </div>
                  <h4 className="mb-3">Email Address</h4>
                  <p className="mb-2"><a href="mailto:mcboa82@gmail.com">mcboa82@gmail.com</a></p>
                  <a
                    className="btn btn-primary px-4"
                    href="mailto:mcboa82@gmail.com"
                  >
                    Email Now <i className="fa fa-arrow-right ms-2"></i>
                  </a>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.5s"
              >
                <div className="bg-light text-center h-100 p-5">
                  <div
                    className="btn-square bg-white rounded-circle mx-auto mb-4"
                    style={{ width: "90px", height: "90px" }}
                  >
                    <i className="fa fa-map-marker-alt fa-2x text-primary"></i>
                  </div>
                  <h4 className="mb-3">Office Address</h4>
                  <p className="mb-2">
                    C/O Maintenance Office, Mapsko Casa Bella, Sector 82,
                    Gurugram, Haryana
                  </p>
                  <a
                    className="btn btn-primary px-4"
                    href="https://goo.gl/maps/qMUoDDavJ7D7TCzRA"
                    target="blank"
                  >
                    Direction <i className="fa fa-arrow-right ms-2"></i>
                  </a>
                </div>
              </div>
            </div>

            <div className="row g-5">
              <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                <p className="fw-medium text-uppercase text-primary mb-2">
                  Contact Us
                </p>
                <h1 className="display-5 mb-4">
                  If You Have Any Queries, Please Feel Free To Contact Us
                </h1>
                <div className="row g-4">
                  <div className="col-6">
                    <div className="d-flex">
                      <div className="flex-shrink-0 btn-square bg-primary rounded-circle">
                        <i className="fa fa-phone-alt text-white"></i>
                      </div>
                      <div className="ms-3">
                        <h6>Call Us</h6>
                        <span><a href="tel:919910261081">+91-9910261081</a></span>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="d-flex">
                      <div className="flex-shrink-0 btn-square bg-primary rounded-circle">
                        <i className="fa fa-envelope text-white"></i>
                      </div>
                      <div className="ms-3">
                        <h6>Mail Us</h6>
                        <span><a href="mailto:mcboa82@gmail.com">mcboa82@gmail.com</a></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                <form>
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          type="text"
                          value={name}
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                          className="form-control"
                          id="name"
                          placeholder="Your Name"
                        />
                        <label htmlFor="name">Your Name</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder="Your Email"
                        />
                        <label htmlFor="email">Your Email</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <input
                          value={subject}
                          onChange={(e) => {
                            setSubject(e.target.value);
                          }}
                          type="text"
                          className="form-control"
                          id="subject"
                          placeholder="Subject"
                        />
                        <label htmlFor="subject">Subject</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <textarea
                          value={message}
                          onChange={(e) => {
                            setMessage(e.target.value);
                          }}
                          className="form-control"
                          placeholder="Leave a message here"
                          id="message"
                          style={{ height: "150px" }}
                        ></textarea>
                        <label htmlFor="message">Message</label>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-floating">
                        <LoadCanvasTemplate />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-floating">
                        <input
                          value={captcha}
                          onChange={(e) => {
                            setCaptcha(e.target.value);
                          }}
                          type="text"
                          className="form-control"
                          id="captcha"
                          placeholder="Enter Captcha"
                        />
                        <label htmlFor="captcha">Enter Captcha</label>
                      </div>
                    </div>
                    <div className="col-12">

                      <button
                        onClick={() => { submitFeedback() }}
                        className="btn btn-primary py-3 px-5"
                        type="submit"
                      >
                        Send Message
                      </button>
                      {loading && <div style={{ textAlign: "center" }}><CircularProgress size={35} thickness={8} /></div>}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <WebFooter />
    </>
  );
};
