import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import * as apiClient from "../../common/apiClient";
import { containerStyles } from "../../common/formStyles";
import { makeStyles } from '@material-ui/core/styles';
import QuoteDetails from './quote_details';
import CircularProgress from '@material-ui/core/CircularProgress';

const QuoteReport = props => {
    const classes = containerStyles({});
    let { id } = useParams();
    console.log(id, "====QUOTEID====");

    const [loading, setLoading] = useState(false);
    const [quotesData, setQuotesData] = useState([]);
    const [categories, setCategories] = useState<any[]>([]);
    const [quotesDetails, setQuotesDetails] = useState([]);
    const [selectedQuote, setSelectedQuote] = useState<any>(null);

    const quote_details = (id) => {
        setLoading(true);
        apiClient.quote_details(id).then(res => {
            //console.log(res);
            createQuoteDetailsView(res.result);
            setLoading(false);
        }).catch(err => {
            console.log(err);
            setLoading(false);
        });
    }

    
    const get_quote = (id) => {
        setLoading(true);
        apiClient.get_quote(id).then(res => {
            //console.log(res);
            setLoading(false);
            setSelectedQuote(res.result);
        }).catch(err => {
            console.log(err);
            setLoading(false);
        });
    }

    const createQuoteDetailsView = (data) => {
        var outObject = data.reduce(function (a, e) {
            let estKey = (e['category_name']);
            (a[estKey] ? a[estKey] : (a[estKey] = null || [])).push(e);
            return a;
        }, {});
        var keys = Object.keys(outObject);
        setCategories(keys);
        setQuotesDetails(outObject);
    }

    useEffect(() => {
        if (!selectedQuote) return;
        quote_details(selectedQuote.id);
    }, [selectedQuote]);
    
    useEffect(() => {
        if (!id) return;
        get_quote(id);
    }, [id]);

    return (
        <React.Fragment>
            {<div className="flex-column">
                <Paper elevation={3} >
                    {categories && categories.length > 0 && <QuoteDetails keys={categories} data={quotesDetails} quote={selectedQuote} />}
                </Paper>
                {loading && <div style={{ textAlign: "center" }}><CircularProgress size={35} thickness={8} /></div>}
            </div>
            }
        </React.Fragment>
    );
}
export default QuoteReport;