import React from 'react';
import { ReferenceField } from 'react-admin';
import { BooleanField } from 'react-admin';
import { List, Datagrid, TextField, DateField, RichTextField } from 'react-admin';

export const ActivityList = props => {
    return (
        <React.Fragment>
            <List title="List of Facilities" {...props} sort={{ field: 'id', order: 'DESC' }} exporter={false} bulkActionButtons={false}>
                <Datagrid rowClick="edit" >
                    <ReferenceField source="category_id" reference="category" label="Category" link={false}>
                        <TextField source="name" />
                    </ReferenceField>
                    <TextField source="name" />
                    <RichTextField source="description" />
                    <BooleanField source="is_show_qty" label="Show Qty ?" />
                </Datagrid>
            </List>
        </React.Fragment>
    )
}