import React, { useEffect, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import * as apiClient from "../../common/apiClient";
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

//#1976d2" : Royal blue
// 84254A
const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: "#43569b",
        color: theme.palette.common.white,
        textAlign: "center",
        border: "1px solid #000",
        padding: "6px 8px 6px 8px"
    },
    body: {
        fontSize: 14,
        border: "1px solid #000",
        padding: "6px 8px 6px 8px"
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
}))(TableRow);



const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});
const DisplayHeader = ({ title, companies }) => {
    return <StyledTableRow >
        <StyledTableCell component="th" scope="row" colSpan={3} style={{ fontWeight: "bold" }}>
            <p dangerouslySetInnerHTML={{ __html: title }}></p>
        </StyledTableCell>
        {companies && companies.map((item, index) => {
            return (
                <>
                    <StyledTableCell align='right' component="th" scope="row" key={index}>Qty</StyledTableCell>
                    <StyledTableCell align='right' component="th" scope="row" key={index}>Price</StyledTableCell>
                    <StyledTableCell align='right' component="th" scope="row" key={index}>Total Cost</StyledTableCell>
                </>
            )
        })}
    </StyledTableRow>
}


const DisplayTotals = ({ userData, companies }) => {
    const get_total_price = (company) => {
        let compDataArr = userData.filter((inner) => company == inner.company_name);
        let costArr = compDataArr.map((item) => item.price);
        let totalValue = 0.000;
        costArr.forEach(element => {
            totalValue += parseFloat(element);
        });
        return totalValue.toFixed(4);
    }

    const get_total_cost = (company) => {
        let compDataArr = userData.filter((inner) => company == inner.company_name);
        let costArr = compDataArr.map((item) => item.total_cost);
        let totalValue = 0.000;
        costArr.forEach(element => {
            totalValue += parseFloat(element);
        });
        return totalValue.toFixed(4);
    }

    return (
        <>
            {companies && companies.map((item, index) => {
                return (
                    <>
                        {/* <StyledTableCell align='right' style={{ fontWeight: "bold" }} key={index}></StyledTableCell>
                        <StyledTableCell align='right' style={{ fontWeight: "bold" }} key={index}>{get_total_price(item)}</StyledTableCell> */}
                        <StyledTableCell align='right' colSpan={3} style={{ fontWeight: "bold" }} key={index}>{get_total_cost(item)}</StyledTableCell>
                    </>
                )
            })}
        </>
    )
}

const DisplaySubmittedValues = ({ record, userData, companies }) => {

    const get_user_data = (company) => {
        let index = userData.findIndex((inner) => company == inner.company_name && inner.quote_facility_id == record.quote_facility_id)
        if (index != -1) {
            let d = userData[index];
            return d;
        }
        else
            return {
                total_cost: "",
                qty: "",
                price: ""
            };
    }
    return (
        <>
            {companies && companies.map((item, index) => {
                return (
                    <>
                        <StyledTableCell align='right'>{get_user_data(item).submitted_qty}</StyledTableCell>
                        <StyledTableCell align='right'>{get_user_data(item).price}</StyledTableCell>
                        <StyledTableCell align='right'>{get_user_data(item).total_cost}</StyledTableCell>
                    </>
                )
            })}
        </>
    )
}



export default function QuoteDetails({ keys, data, quote }) {
    const classes = useStyles();
    const [userQuoteSubmissions, setUserQuoteSubmissions] = useState([]);
    const [ifBidSubmitted, setIsBidSubmitted] = useState(true);
    const [companies, setCompanies] = useState<any[]>([]);
    const [userCompanies, setUserCompanies] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    let mainIndex = 0;

    const set_companies = (submissions) => {
        let comps = submissions.map((item, index) => item.company_name);
        let s = new Set(comps);
        let arr = Array.from(s.values());
        //console.log(arr, "AARRRR companies");
        setCompanies(arr);
    }
    const get_user_submissions = (quote_id) => {
        setLoading(true);
        apiClient.quote_submissions(quote_id).then(res => {
            setLoading(false);
            console.log(res, "======RES SUBMISSIONS=====");
            if (res.status == "success") {
                setUserQuoteSubmissions(res.result);
                set_companies(res.result);
                console.log(res.result, "======SUBMISSIONS=====");
            }
        }).catch(err => {
            setLoading(false);
        })
    }


    useEffect(() => {
        if (!quote) return;
        setIsBidSubmitted(prev => true);
        setUserQuoteSubmissions(prev => []);
        get_user_submissions(quote.id);
    }, [quote])
    return (
        <>
            {quote && <div style={{ fontSize: 16, fontWeight: "bold", textAlign: "center", margin: "20px" }}>{quote.name}</div>}

            <Table className={classes.table} stickyHeader size="small" aria-label="customized dense table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Sr.</StyledTableCell>
                        <StyledTableCell>Particulars</StyledTableCell>
                        <StyledTableCell>Required Qty</StyledTableCell>
                        {companies && companies.map((item, index) => {
                            return (
                                <StyledTableCell colSpan={3} key={index}>{item}</StyledTableCell>
                            )
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {keys.map((key, index) => {

                        return (
                            <>
                                <DisplayHeader key={index} title={key} companies={companies} />
                                {data[key] && data[key].length > 0 && data[key].map((row, rowIndex) => {
                                    mainIndex++;
                                    return (
                                        <StyledTableRow key={rowIndex}>
                                            <StyledTableCell component="th" scope="row">
                                                {mainIndex}
                                            </StyledTableCell>
                                            <StyledTableCell >{row.facility_name} <br /> <span dangerouslySetInnerHTML={{ __html: row.description }}></span> </StyledTableCell>
                                            <StyledTableCell align='center'>{(row.is_show_qty) == 1 ? row.qty : ""}</StyledTableCell>
                                            <DisplaySubmittedValues record={row} userData={userQuoteSubmissions} companies={companies} />
                                        </StyledTableRow>
                                    )
                                })}
                                <StyledTableRow>
                                    <StyledTableCell style={{ fontWeight: "bold" }} align='right' colSpan={3} component="th" scope="row">
                                        Total Cost
                                    </StyledTableCell>
                                    <DisplayTotals userData={userQuoteSubmissions} companies={companies} />
                                </StyledTableRow>
                            </>
                        )
                    })}
                </TableBody>
            </Table>
            {loading && <div style={{ textAlign: "center" }}><CircularProgress size={35} thickness={8} /></div>}
        </>
    );
}
