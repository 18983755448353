import React, { useState, useEffect } from 'react';
import { useMediaQuery } from '@material-ui/core';

import * as apiClient from "../common/apiClient";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import QuoteList from '../dashboard/quote_list';
import QuoteDetails from '../dashboard/quote_details';
import { WebHeader } from './common/Header';
import { WebFooter } from './common/Footer';


export interface IHeader {
    SubjectCount: number;
    TestCount: number;
    QuestionCount: number;
    CandidateCount: number;
}

const useStyles = makeStyles({
    flexCenter: { display: 'flex', justifyContent: "center", alignContent: "center" },
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
    root: {
        width: '100%',
    },
    heading: {
        fontSize: 20,
        fontWeight: 'bold',
    },
});

const WebDashboard = () => {
    const [role, setRole] = useState("Student");
    const [assignments, setAssignments] = useState([]);
    const [quotesData, setQuotesData] = useState([]);
    const [categories, setCategories] = useState<any[]>([]);
    const [quotesDetails, setQuotesDetails] = useState([]);
    const [selectedQuote, setSelectedQuote] = useState(null);
    const [userData, setUserData] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState<any>("");
    const [isLoggedIn, setIsLoggedIn] = useState(false);



    const styles = useStyles({});


    const quotes_for_today = () => {
        setLoading(true);
        apiClient.quotes_for_today().then(res => {
            //console.log(res);
            setQuotesData(res.result);
            setLoading(false);
        }).catch(err => {
            console.log(err);
            setLoading(false);
        });
    }

    const createQuoteDetailsView = (data) => {
        var outObject = data.reduce(function (a, e) {
            let estKey = (e['category_name']);
            (a[estKey] ? a[estKey] : (a[estKey] = null || [])).push(e);
            return a;
        }, {});
        var keys = Object.keys(outObject);
        setCategories(keys);
        setQuotesDetails(outObject);
    }


    const quote_details = (item) => {
        setLoading(true);
        apiClient.quote_details(item.id).then(res => {
            //console.log(res);
            createQuoteDetailsView(res.result);
            setLoading(false);
            setSelectedQuote(item);
        }).catch(err => {
            console.log(err);
            setLoading(false);
        });
    }

    const onSubmitBid = () => {
        setCategories([]);
        setQuotesDetails([]);
        setSelectedQuote(null);
    }




    useEffect(() => {
        let role = localStorage.getItem("role");
        setRole(role!);

        let userid = localStorage.getItem("userid");
        if (!userid || userid == "") {
            setIsLoggedIn(false);
        }
        else
            setIsLoggedIn(true);

        let displayName = localStorage.getItem('displayName');
        setName(displayName);

        quotes_for_today();
    }, []);

    return (
        <>
            <WebHeader />
            <div className='page'  style={{ padding: 20, }}>
                <div style={{ paddingTop: 20, }}>
                    {isLoggedIn && <div className='user-info'>
                        <Paper elevation={3} >
                            <div>Welcome <strong>{name}</strong></div>

                        </Paper>
                    </div>}
                    {!selectedQuote && 
                        <div className={styles.flexColumn}>
                            <Paper elevation={3} >
                                <h2 style={{ textAlign: "center" }}>Open Tenders</h2>
                                {quotesData && quotesData.length > 0 && <QuoteList data={quotesData} onQuoteClick={(item) => { quote_details(item); }} isLoggedIn={isLoggedIn} />}
                                {loading && <div style={{textAlign:"center"}}><CircularProgress size={35} thickness={8} /></div>}
                                {!loading && quotesData && quotesData.length == 0 &&  <div style={{ textAlign: "center" }}>No Tenders yet</div>}
                            </Paper>
                        </div>
                    }
                    { <div className={styles.flexColumn}>
                            <Paper elevation={3} >
                                {categories && categories.length > 0 && <QuoteDetails isLoggedIn={isLoggedIn} onGoBack={onSubmitBid} onSubmitBid={onSubmitBid} keys={categories} data={quotesDetails} quote={selectedQuote} />}
                            </Paper>
                        </div>
                    }
                </div>
            </div>
            <WebFooter />
        </>
    );
}

export default WebDashboard;
