import React from 'react';
import { Create, SimpleForm, TextInput, required, DateInput, ReferenceInput, SelectInput, FileInput, FileField } from 'react-admin';
import { FormToolbar } from "../../common/FormToolbar";
import { BooleanInput } from 'react-admin';

export const ActivityCreate = props => {
    let defaultValues = {
        is_show_qty: false,
    };
    return (
        <React.Fragment>
            <Create label="Edit Category" {...props} undoable={false}>
                <SimpleForm redirect="list" initialValues={defaultValues}
                    toolbar={<FormToolbar {...props} />}
                    variant="outlined">

                    <ReferenceInput label="Group" source="doc_group_id" reference="docgroup" fullWidth={true}
                        sort={{ field: 'name', order: 'asc' }}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>

                    <TextInput source="name" fullWidth={true} validate={[required()]} />
                    <FileInput validate={[required()]}
                        source="path" label="Document">
                        <FileField source="path" title="Document" />
                    </FileInput>
                </SimpleForm>
            </Create>
        </React.Fragment>
    );
}