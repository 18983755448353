import { Theme, useMediaQuery } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import ImportContactsOutlinedIcon from '@material-ui/icons/ImportContactsOutlined';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import PermContactCalendarOutlinedIcon from '@material-ui/icons/PermContactCalendarOutlined';
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import React, { FC, useState } from 'react';
import { DashboardMenuItem, MenuItemLink, useTranslate } from 'react-admin';
import { useSelector } from 'react-redux';
import { AppState } from '../types';
import SubMenu from './SubMenu';
import FeedbackIcon from '@material-ui/icons/Feedback';
import PeopleIcon from '@material-ui/icons/People';
import AssignmentIcon from '@material-ui/icons/Assignment';
import HomeWorkIcon from '@material-ui/icons/HomeWork';

type MenuName =
  | 'menuConfiguration'
  | 'menuFacultyOperation'
  | 'menuStudentOperation';

interface Props {
  dense: boolean;
  logout: () => void;
  onMenuClick: () => void;
}

const Menu: FC<Props> = ({ onMenuClick, dense, logout }) => {
  const [state, setState] = useState({
    menuConfiguration: true,
    menuFacultyOperation: true,
    menuStudentOperation: true
  });
  const translate = useTranslate();
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xs')
  );
  const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
  useSelector((state: AppState) => state.theme); // force rerender on theme change

  const handleToggle = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  let role = localStorage.getItem('role');
  let isSuperAdmin = role == 'super administrator';
  let isAdministrator = role == 'administrator';
  let isPrincipal = role == 'principal';
  let isFaculty = role == 'faculty';
  let isStudent = role == 'student';

  return (
    <div>
      <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
      <Divider />
      {(isSuperAdmin || isAdministrator) && (
        <>
          <MenuItemLink
            to={`/quote`}
            primaryText='Tenders'
            leftIcon={<AssessmentOutlinedIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
           <MenuItemLink
            to={`/feedback`}
            primaryText='Feedback'
            leftIcon={<FeedbackIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <SubMenu
            handleToggle={() => handleToggle('menuConfiguration')}
            isOpen={state.menuConfiguration}
            sidebarIsOpen={open}
            name='Configuration'
            icon={<SettingsIcon />}
            dense={dense}
          >
            <MenuItemLink
              to={`/docgroup`}
              primaryText='Document Groups'
              leftIcon={<AssessmentOutlinedIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            <MenuItemLink
              to={`/document`}
              primaryText='Documents'
              leftIcon={<AssessmentOutlinedIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            <MenuItemLink
              to={`/teammember`}
              primaryText='Team Members'
              leftIcon={<PeopleIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            <MenuItemLink
              to={`/category`}
              primaryText='Category'
              leftIcon={<AssignmentIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            <MenuItemLink
              to={`/facility`}
              primaryText='Services'
              leftIcon={<HomeWorkIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            <MenuItemLink
              to={`/user`}
              primaryText='Users'
              leftIcon={<SchoolOutlinedIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          </SubMenu>
        </>
      )}
      <Divider />
      <MenuItemLink
        to={`/changepassword`}
        primaryText='Change Password'
        leftIcon={<VpnKeyIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />
      {logout}
      <Divider />
    </div>
  );
};

export default Menu;
