import React, { useState, useEffect } from 'react';

import "./style/bootstrap.min.css";
import "./style/style.css";
import carousel from "./images/carousel-1.jpg";
import about from "./images/about-1.jpg";
import about1 from "./images/about-2.jpg";
import { WebHeader } from './common/Header';
import { WebFooter } from './common/Footer';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as apiClient from '../common/apiClient';
import { toast } from 'react-toastify';
import { useLogin, useNotify, useTranslate } from 'ra-core';
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';

export const WebRegister = (props) => {

    const [loading, setLoading] = useState(false);
    const [loginLoading, setLoginLoading] = useState(false);
    const [loginUsername, setLoginUsername] = useState("");
    const [loginpassword, setLoginPassword] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [displayName, setDisplayname] = useState("");
    const [contactPerson, setContactPerson] = useState("");
    const [phone, setPhone] = useState("");
    const [captcha, setCaptcha] = useState("");

    const notify = useNotify();
    let history = useHistory();

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const login = (email, password) => {

        if (email == "" || email.length <= 3) {
            toast.error("Please provide the email address");
            return;
        }
        if (!validateEmail(email)) {
            toast.error("Please provide the valid email address");
            return;
        }
        if (password == "" || password.length < 5) {
            toast.error("Please provide the correct password.");
            return;
        }


        setLoginLoading(true);
        let data = {
            username: email,
            password: password
        };
        apiClient
            .loginUser(data)
            .then((response) => {
                setLoginLoading(false);
                if (response.status === 'success') {
                    let objUser = response.result;

                    let designation = '';
                    if (objUser.employee && objUser.employee.designation)
                        designation = objUser.employee.designation;
                    else designation = objUser.role;

                    localStorage.setItem('userid', objUser.id);
                    localStorage.setItem('username', objUser.username);
                    localStorage.setItem('password', objUser.password);
                    localStorage.setItem('displayName', objUser.display_name);
                    localStorage.setItem('token', objUser.token);
                    localStorage.setItem('role', objUser.role);
                    localStorage.setItem('reference_id', objUser.reference_id);
                    localStorage.setItem('designation', designation);
                    if (objUser.role == "company")
                        history.push('/tenders');
                    else
                        history.push('/');
                } else {
                    localStorage.clear();
                    toast.error(response.message);
                }
            })
            .catch((err) => {
                setLoginLoading(false);
                toast.error(err);
            });
    };


    const register = () => {

        if (username == "" || username.length <= 3) {
            toast.error("Please provide the email address");
            return;
        }
        if (!validateEmail(username)) {
            toast.error("Please provide the valid email address");
            return;
        }
        if (password == "" || password.length < 5) {
            toast.error("Please provide the valid password");
            return;
        }
        if (password != confirmPassword) {
            toast.error("Password and confirm password are not same");
            return;
        }

        if (displayName == "" || displayName.length <= 3) {
            toast.error("Please provide the company name");
            return;
        }
        if (contactPerson == "" || contactPerson.length <= 3) {
            toast.error("Please provide the contact person name");
            return;
        }
        if (phone == "" || !(phone.length == 10)) {
            toast.error("Please provide the valid phone number");
            return;
        }

        if (captcha == "") {
            toast.error("Please enter Captcha");
            return;
        }

        if (validateCaptcha(captcha) == false) {
            toast.error("Captcha not  valid");
            return;
        }
        
        let data = {
            username: username,
            password: password,
            display_name: displayName,
            company_name: displayName,
            contact_name: contactPerson,
            contact_phone: phone,
            contact_email: username
        };
        setLoading(true);
        apiClient.registerBidder(data).then(res => {
            // console.log(res, "====res====");
            setLoading(false);
            if (res.status == "failed") {
                toast.error(res.message);
            } else {
                login(username, password);
            }
        }).catch(err => {
            setLoading(false);
            toast.error("Error on Registring")
        });
    }

    useEffect(() => {
        loadCaptchaEnginge(6);
      }, []);
    return (
        <>
            <WebHeader />
            <div className='page'>
                <div className="container-xxl py-5">
                    <div className="container">


                        <div className="row g-5">
                            <div className="col-lg-6 wow" data-wow-delay="0.1s">
                                {/* <p className="fw-medium text-uppercase text-primary mb-2">Login</p> */}
                                <h1 className="display-6 mb-4">Login</h1>
                                <div className="row g-3">
                                    <div className="col-md-12">
                                        <div className="form-floating">
                                            <input type="email" className="form-control" id="email" placeholder="Your Email" onChange={(e) => {
                                                setLoginUsername(e.target.value);
                                            }}
                                                value={loginUsername} />
                                            <label htmlFor="email">Email Address</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-floating">
                                            <input type="password" className="form-control" id="password" placeholder="Your Password" onChange={(e) => {
                                                setLoginPassword(e.target.value);
                                            }}
                                                value={loginpassword} />
                                            <label htmlFor="password">Password</label>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <button className="btn btn-primary py-3 px-5" type="button" onClick={() => {
                                            login(loginUsername, loginpassword);
                                        }}>Login</button>
                                        {loginLoading && <div style={{ textAlign: "center" }}><CircularProgress size={30} thickness={8} /></div>}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 wow" data-wow-delay="0.5s">
                                <form>
                                    <h1 className="display-6 mb-4">Register</h1>
                                    <div className="row g-3">
                                        <div className="col-md-12">
                                            <div className="form-floating">
                                                <input type="email" className="form-control" id="email" placeholder="Your Email"
                                                    onChange={(e) => {
                                                        setUsername(e.target.value);
                                                    }}
                                                    value={username}
                                                />
                                                <label htmlFor="email">Email Address</label>
                                            </div>
                                            <div className='hint'>You will receive all of communication on this email.</div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-floating">
                                                <input type="password" className="form-control" id="password" placeholder="Your Password"
                                                    onChange={(e) => {
                                                        setPassword(e.target.value);
                                                    }}
                                                    value={password}
                                                />
                                                <label htmlFor="password">Password</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-floating">
                                                <input type="password" className="form-control" id="confirm-password" placeholder="Confirm Password"
                                                    onChange={(e) => {
                                                        setConfirmPassword(e.target.value);
                                                    }}
                                                    value={confirmPassword}
                                                />
                                                <label htmlFor="confirm-password">Confirm Password</label>
                                            </div>
                                        </div>
                                        <div className='hint'>Password must be atleast 5 characters long.</div>
                                        <div className="col-md-12">
                                            <div className="form-floating">
                                                <input type="text" className="form-control" id="company-name" placeholder="Company Name"
                                                    onChange={(e) => {
                                                        setDisplayname(e.target.value);
                                                    }}
                                                    value={displayName} />
                                                <label htmlFor="company-name">Company Name</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-floating">
                                                <input type="text" className="form-control" id="contact-person" placeholder="Contact Person"
                                                    onChange={(e) => {
                                                        setContactPerson(e.target.value);
                                                    }}
                                                    value={contactPerson} />
                                                <label htmlFor="contact-person">Contact Person</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-floating">
                                                <input type="text" className="form-control" id="phone" placeholder="Phone"
                                                    onChange={(e) => {
                                                        setPhone(e.target.value);
                                                    }}
                                                    value={phone} />
                                                <label htmlFor="phone">Phone</label>
                                            </div>
                                            <div className='hint'>Please do not add prefix in mobile number.</div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-floating">
                                                <LoadCanvasTemplate />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-floating">
                                                <input
                                                    value={captcha}
                                                    onChange={(e) => {
                                                        setCaptcha(e.target.value);
                                                    }}
                                                    type="text"
                                                    className="form-control"
                                                    id="captcha"
                                                    placeholder="Enter Captcha"
                                                />
                                                <label htmlFor="captcha">Enter Captcha</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <button className="btn btn-primary py-3 px-5" type="button" onClick={() => {
                                                register();
                                            }}>Register</button>
                                            {loading && <div style={{ textAlign: "center" }}><CircularProgress size={30} thickness={8} /></div>}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <WebFooter />
        </>
    );
};
