import React from 'react';
import { Edit, SimpleForm, TextInput, required, DateInput,FileInput,FileField } from 'react-admin';
import { FormToolbar } from "../../common/FormToolbar";
import { BooleanInput } from 'react-admin';
import { ReferenceInput } from 'react-admin';
import { SelectInput } from 'react-admin';
import { ImageInput } from 'react-admin';
import { ImageField } from 'react-admin';
import { NumberInput } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

export const ActivityEdit = props => {
    return (
        <React.Fragment>
            <Edit label="Edit Team Member" {...props} undoable={false}>
                <SimpleForm toolbar={<FormToolbar {...props} />}
                    variant="outlined">
                    
                    <TextInput source="name" fullWidth={true} validate={[required()]} />
                    <TextInput source="designation" fullWidth={true} validate={[required()]} />
                    <RichTextInput source="description" fullWidth={true} />
                    <ImageInput source="photo_url" label="Photo" accept="image/*" className="logourl">
                        <ImageField source="photo_url" title="Photo" />
                    </ImageInput>
                    <TextInput source="sort_order" fullWidth={true} validate={[required()]}/>
                    <BooleanInput source="is_active"/>
                </SimpleForm>
            </Edit>
        </React.Fragment>
    );
}