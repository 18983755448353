import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
//#1976d2" : Royal blue
// 84254A
const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: "#43569b",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
}))(TableRow);



const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});

export default function DocumentList({ type, data, onClick }) {
    const classes = useStyles();
    return (
        <Table className={classes.table} size="small" aria-label="customized table">
            <TableHead>
                <TableRow>
                    {/* <StyledTableCell>Sno.</StyledTableCell> */}
                    <StyledTableCell>Name</StyledTableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((row, index) => (
                    <StyledTableRow key={index}>
                        {/* <StyledTableCell>{index+1}</StyledTableCell> */}
                        <StyledTableCell component="th" scope="row">
                            {type == "document" && <a href={row.path} target="_blank">{row.name}</a>}
                            {type == "category" && <div style={{cursor:"pointer"}} onClick={() => {onClick(row.id);}}>{row.name}</div>}
                        </StyledTableCell>
                    </StyledTableRow>
                ))}
            </TableBody>
        </Table>
    );
}
