import React, { useCallback, useState } from 'react';
import {
    Edit, SimpleForm, TextInput, required, DateInput, BooleanField,
    ReferenceManyField, Datagrid, ReferenceField, TextField,
    useRefresh, TabbedForm, FormTab, RichTextField
} from 'react-admin';
import { FormToolbar } from "../../common/FormToolbar";
import { Button, Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DisplayDateTime } from '../../common/DisplayDate';
import CDeleteButton from '../../common/CDeleteButton';
import QuoteFacilities from './QuoteFacilities';
import RichTextInput from 'ra-input-rich-text';

const myStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    list: {
        flexGrow: 1,
        transition: theme.transitions.create(['all'], {
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    listWithDrawer: {
        marginRight: 300,
    },
    drawerPaper: {
        zIndex: 100,
        marginTop: 50,
        width: 650,
    },
}));

export const ActivityEdit = props => {
    const myclasses = myStyles({});
    const refresh = useRefresh();
    const [isaddFacility, setIsAddFacility] = useState(false);

    const handleClose = useCallback(() => {
        setIsAddFacility(false);
        refresh();
    }, []);


    return (
        <React.Fragment>
            <Edit label="Edit Subject" {...props}>
                <TabbedForm redirect="list" toolbar={<FormToolbar {...props} showDelete={false} />} variant="outlined" >
                    <FormTab label="Quote Detail">
                        <TextInput source="name" fullWidth={true} validate={[required()]} />
                        <DateInput source="start_date" fullWidth={true} validate={[required()]} />
                        <DateInput source="end_date" fullWidth={true} validate={[required()]} />
                        <RichTextInput source="description" fullWidth={true} />
                    </FormTab>
                    <FormTab  label="Services">
                        <Button variant="contained" onClick={() => { setIsAddFacility(true); }}
                            color="secondary">
                            Add Services
                        </Button>
                        <ReferenceManyField fullWidth={true}
                            addLabel={false}
                            sort={{ field: 'id', order: 'ASC' }}
                            reference="quote_facility"
                            perPage={500}
                            target="quote_id">
                            <Datagrid>
                                <CDeleteButton {...props} />
                                <ReferenceField source="facility_id" reference="facility" label="Service" link={false}>
                                    <TextField source="name" />
                                </ReferenceField>
                                <TextField source="qty" label="Qty" link={false} />
                                <RichTextField source="remarks" label="Remarks" link={false} />
                            </Datagrid>
                        </ReferenceManyField>

                    </FormTab>
                </TabbedForm>

            </Edit>
            <Drawer
                anchor="right"
                onClose={handleClose}
                classes={{ paper: myclasses.drawerPaper, }}
                open={isaddFacility} >
                {isaddFacility && <QuoteFacilities
                    onClose={handleClose}
                    onCancel={handleClose}
                    id={props.id}
                    {...props} />}
            </Drawer>
        </React.Fragment>
    );
}