import React, { useEffect, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import * as apiClient from "../common/apiClient"
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

//#1976d2" : Royal blue
// 84254A
const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: "#43569b",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
}))(TableRow);



const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});
const DisplayHeader = ({ title }) => {
    return <StyledTableRow >
        <StyledTableCell component="th" scope="row" colSpan={7} style={{ fontWeight: "bold" }}>
            <p dangerouslySetInnerHTML={{__html: title }}></p>
        </StyledTableCell>
    </StyledTableRow>
}

const DisplayInputs = ({ record, userData }) => {
    let user_id = localStorage.getItem("userid");
    const [qty, setQty] = useState(1);
    const [cost, setCost] = useState(0);
    const [myRecord, setMyRecord] = useState(null);

    const updateQty = () => {

        if (myRecord) {
            if (myRecord.qty == qty && myRecord.price == cost) return;
        }
        let data = {
            quote_id: record.quote_id,
            user_id: user_id,
            quote_facility_id: record.quote_facility_id,
            qty: qty,
            price: cost,
            total_cost: qty * cost,
            remarks: ""
        }
        apiClient.addEntiyRecord("quote_submission", data).then(res => {
            if (res.status == "success" && res.result)
                setMyRecord(res.result);

        }).catch(err => {
            toast.error("Error on saving the QTY");
        });
    }
    useEffect(() => {
        if (!userData) return;
        let index = userData.findIndex((inner) => inner.quote_facility_id == record.quote_facility_id)
        if (index != -1) {
            let d = userData[index];
            setQty(d.qty);
            setCost(parseInt(d.price));
            setMyRecord(d);
        }

    }, [userData]);
    return (
        <>
            <StyledTableCell>
                {record.is_show_qty == 1 && <input type="number"
                    value={qty}
                    onChange={(e) => {
                        setQty(e.target.value);
                    }}
                    onBlur={() => {
                        updateQty();
                    }}
                    className='qty-box'
                />}
            </StyledTableCell>
            <StyledTableCell >
                <input type="number"
                    value={cost}
                    onChange={(e) => {
                        setCost(e.target.value);
                    }}
                    onBlur={() => {
                        updateQty();
                    }}
                    className='qty-box'
                />
            </StyledTableCell>
            <StyledTableCell>{qty * cost}</StyledTableCell>

        </>
    )
}

const DisplaySubmittedValues = ({ record, userData }) => {
    let user_id = localStorage.getItem("userid");
    const [bid, setBid] = useState(null);


    useEffect(() => {
        if (!userData) return;
        let index = userData.findIndex((inner) => inner.quote_facility_id == record.quote_facility_id)
        if (index != -1) {
            let d = userData[index];
            setBid(d);
        }
    }, [userData]);
    return (
        <>
            {bid && <StyledTableCell>
                {bid.qty}
            </StyledTableCell>}
            {bid && <StyledTableCell >
                {bid.price}
            </StyledTableCell>}
            {bid && <StyledTableCell>{bid.total_cost}</StyledTableCell>}

        </>
    )
}



export default function QuoteDetails({ keys, data, quote, onSubmitBid, onGoBack, isLoggedIn }) {
    const classes = useStyles();
    const [userQuoteSubmissions, setUserQuoteSubmissions] = useState([]);
    const [ifBidSubmitted, setIsBidSubmitted] = useState(true);
    const [loading, setLoading] = useState(false);
    let mainIndex = 0;

    const isBidSubmitted = (quote_id) => {
        let user_id = localStorage.getItem("userid");
        apiClient.is_quote_submitted(user_id, quote_id).then(res => {
            if (res.status == "success") {
                setIsBidSubmitted(res.result != null);
            }
        }).catch(err => {

        })
    }

    const get_user_submissions = (quote_id) => {
        let user_id = localStorage.getItem("userid");
        setLoading(true);
        apiClient.user_quote_submissions(user_id, quote_id).then(res => {
            setLoading(false);
            if (res.status == "success") {
                setUserQuoteSubmissions(res.result);
            }
        }).catch(err => {
            setLoading(false);
        })
    }
    const submitBid = () => {
        let check = window.confirm("You cannot modify the tender after submission. Do you want to proceed ?");
        if (!check) return;
        let user_id = localStorage.getItem("userid");
        let data = {
            quote_id : quote.id,
            user_id,
        }
        setLoading(true);
        apiClient.submit_quote(data).then(res => {
            setLoading(false);
            if (res.status == "success") {
                toast.success("Bid Submitted successfully");
                onSubmitBid();
            }
        }).catch(err => {
            setLoading(false);
            toast.error("Erro Submitting quote.");
        });
    }

    useEffect(() => {
        if (!quote) return;
        setIsBidSubmitted(prev => true);
        setUserQuoteSubmissions(prev => []);
        if (isLoggedIn) {
            get_user_submissions(quote.id);
            isBidSubmitted(quote.id);
        }
    }, [quote])
    return (
        <>
            {quote &&  <h3 style={{ textAlign: "center", marginTop2: "20px" }}>{quote.name}</h3>}
            <div className='quote-details' style={{padding:15, height:255, overflowX:"auto"}}>
            {quote && <div style={{textAlign:"justify"}}><p dangerouslySetInnerHTML={{__html: quote.description}}></p></div>}
            </div>
            <Table className={classes.table} size="small" aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Sr.</StyledTableCell>
                        <StyledTableCell>Particulars</StyledTableCell>
                        <StyledTableCell>Description</StyledTableCell>
                        <StyledTableCell>Required Qty</StyledTableCell>
                        {isLoggedIn && <StyledTableCell>Qty</StyledTableCell>}
                        {isLoggedIn && <StyledTableCell>Price(All Inclusive)</StyledTableCell>}
                        {isLoggedIn && <StyledTableCell>Total Price</StyledTableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {keys.map((key, index) => {

                        return (
                            <>
                                <DisplayHeader key={index} title={key} />
                                {data[key] && data[key].length > 0 && data[key].map((row, rowIndex) => {
                                    mainIndex++;
                                    return (
                                        <StyledTableRow key={rowIndex}>
                                            <StyledTableCell component="th" scope="row">
                                                {mainIndex}
                                            </StyledTableCell>
                                            <StyledTableCell >{row.facility_name}</StyledTableCell>
                                            <StyledTableCell><span dangerouslySetInnerHTML={{__html: row.description}}></span></StyledTableCell>
                                            <StyledTableCell>{(row.is_show_qty) == 1 ? row.qty : ""}</StyledTableCell>
                                            {isLoggedIn &&  !ifBidSubmitted && <DisplayInputs record={row} userData={userQuoteSubmissions} />}
                                            {isLoggedIn &&  ifBidSubmitted && <DisplaySubmittedValues record={row} userData={userQuoteSubmissions} />}
                                        </StyledTableRow>
                                    )
                                })}
                            </>
                        )
                    })}
                </TableBody>
            </Table>
            {keys && keys.length > 0 && <div className='quote-actions'>
                {!ifBidSubmitted && !loading && <Button variant="contained" style={{ margin: "10px" }} onClick={() => { submitBid(); }}
                    color="secondary">
                    Submit
                </Button>}
                <Button variant="contained" style={{ margin: "10px" }} onClick={() => { onGoBack(); }}
                    color="secondary">
                    Back
                </Button>
            </div>}
            {loading && <div style={{ textAlign: "center" }}><CircularProgress size={35} thickness={8} /></div>}
        </>
    );
}
