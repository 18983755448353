import polyglotI18nProvider from 'ra-i18n-polyglot';
import React, { useEffect, useState } from 'react';
import { Admin, EditGuesser, ListGuesser, Resource } from 'react-admin';
import './App.css';
import authProvider from './authProvider';
import { APIUrl } from './env';
import { Dashboard } from './dashboard';
import englishMessages from './i18n/en';
import { Layout, Login } from './layout';
import { MyDataProvider } from './providers/MyDataProvider';
import customRoutes from './routes';

import quote from './screens/quote';
import facility from './screens/facility';
import feedback from './screens/feedback';
import category from './screens/category';
import docgroup from './screens/docgroup';
import document from './screens/document';
import team from './screens/team';
import themeReducer from './themeReducer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { WebHome } from './frontend/Home';

const i18nProvider = polyglotI18nProvider((locale) => {
  if (locale === 'fr') {
    return import('./i18n/fr').then((messages) => messages.default);
  }

  // Always fallback on english
  return englishMessages;
}, 'en');

const App = () => {
  const [dataProvider, setDataProvider] = useState({});

  useEffect(() => {
    let provider = MyDataProvider(APIUrl);
    setDataProvider(provider);
  }, []);
 
  return (
    <>
      <Admin
        title=''
        dataProvider={dataProvider}
        customReducers={{ theme: themeReducer }}
        customRoutes={customRoutes}
        authProvider={authProvider}
        dashboard={Dashboard}
        loginPage={WebHome}
        layout={Layout}
        i18nProvider={i18nProvider}
      >
        <Resource name='category' {...category} />
        <Resource name='facility' {...facility} />
        <Resource name='feedback' {...feedback} />
        <Resource name='quote_facility' list={ListGuesser} edit={EditGuesser} />
        <Resource name='docgroup' {...docgroup} />
        <Resource name='document' {...document} />
        <Resource name='teammember' {...team} />
        <Resource name='quote' {...quote} />
        <Resource name='changepassword' list={ListGuesser} edit={EditGuesser} />
        <Resource name='user' />
        
      </Admin>
      <ToastContainer theme="dark"/>
    </>
  );
};

export default App;
