import React from 'react';
import { Edit, SimpleForm, TextInput, required, DateInput,FileInput,FileField } from 'react-admin';
import { FormToolbar } from "../../common/FormToolbar";
import { BooleanInput } from 'react-admin';
import { ReferenceInput } from 'react-admin';
import { SelectInput } from 'react-admin';

export const ActivityEdit = props => {
    return (
        <React.Fragment>
            <Edit label="Edit Category" {...props} undoable={false}>
                <SimpleForm toolbar={<FormToolbar {...props} />}
                    variant="outlined">
                        
                        <ReferenceInput label="Group" source="doc_group_id" reference="docgroup" fullWidth={true}
                        sort={{ field: 'name', order: 'asc' }}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>

                    <TextInput source="name" fullWidth={true} validate={[required()]} />
                    <FileInput validate={[required()]}
                        source="path" label="Document">
                        <FileField source="path" title="Document" />
                    </FileInput>
                  
                </SimpleForm>
            </Edit>
        </React.Fragment>
    );
}