import React, { forwardRef } from 'react';
import { AppBar, UserMenu, MenuItemLink, useTranslate } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/core/styles';

import Logo from './Logo';

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
});

const ConfigurationMenu = forwardRef<any, any>((props, ref) => {
    const translate = useTranslate();
    return (
        <MenuItemLink
            ref={ref}
            to="/configuration"
            primaryText={translate('pos.configuration')}
            leftIcon={<SettingsIcon />}
            onClick={props.onClick}
        />
    );
});

const CustomUserMenu = (props: any) => (
    <UserMenu {...props}>
        {/* <ConfigurationMenu /> */}
    </UserMenu>
);

const titleCase = (str: String): string => {
    var sentence = str.toLowerCase().split(" ");
    for (var i = 0; i < sentence.length; i++) {
        sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }
    return sentence.join(" ");
}


const CustomAppBar = (props: any) => {
    const classes = useStyles();
    let displayName = localStorage.getItem("displayName");
    //let role = localStorage.getItem("role");
    let role = localStorage.getItem("designation");
    //let displayString = titleCase(displayName + " (" + role + ")");
    //let displayString = displayName + " (" + role + ")";
    let displayString = displayName;
    return (
        <AppBar {...props} userMenu={<CustomUserMenu />}>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            <Logo />
            <span className={classes.spacer} />
            <span className="MyTitleCase">
                {/* {localStorage.getItem("displayName")} ({localStorage.getItem("role")}) */}
                {displayString}
            </span>
        </AppBar>
    );
};

export default CustomAppBar;
