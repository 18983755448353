import { DataProvider, fetchUtils, HttpError } from 'ra-core';
import * as apiClient from '../common/apiClient';
import { APIUrl } from '../env';
/*
export const VSfetchJson = (url: any, options: fetchUtils.Options = {}) => {
    const requestHeaders = fetchUtils.createHeadersFromOptions(options);

    return fetch(url, { ...options, headers: requestHeaders })
        .then(response =>
            response.text().then(text => ({
                status: response.status,
                statusText: response.statusText,
                headers: response.headers,
                body: text,
            }))
        )
        .then(({ status, statusText, headers, body }) => {
            let json;
            try {
                json = JSON.parse(body);
            } catch (e) {
                // not json, no big deal
            }
            if (status == 409) {
                return Promise.reject(
                    new HttpError(
                        (json && json.error && json.error.message) || statusText,
                        status,
                        json
                    )
                );
            }
            if (status == 422) {
                let message = "**VALIDATION FAILED** ";
                if (json.error && json.error.details && json.error.details.length > 0) {
                    json.error.details.forEach((element: any) => {
                        let f = element;
                        let mf = f.path + " " + f.message;
                        message = message + " , " + mf;
                    });
                }
                return Promise.reject(
                    new HttpError(
                        message,
                        status,
                        json
                    )
                );
            }
            if (status < 200 || status >= 300) {
                return Promise.reject(
                    new HttpError(
                        (json && json.message) || statusText,
                        status,
                        json
                    )
                );
            }
            return Promise.resolve({ status, headers, body, json });
        });
};
*/

const UploadFile = (field: string, data: any) => {
  const formData = new FormData();
  formData.append(field, data.rawFile);
  let finalUrl = `${APIUrl}/upload`;
  return fetch(`${finalUrl}`, {
    method: 'POST',
    body: formData
  })
    .then((response) => response.json())
    .then((responseJson) => {
      return responseJson;
    })
    .catch((error) => {
      console.log(error);
    });
};

const fetchJson = (url, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  let token = localStorage.getItem('token');
  options.headers.set('Authorization', token);
  return fetchUtils.fetchJson(url, options);
};

export const MyDataProvider = (
  apiUrl: string,
  httpClient = fetchJson
): DataProvider => {
  return {
    getList: (resource: any, params: any) => {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const filter = params.filter;
      const filterString = JSON.stringify(filter);
      const finalUrl = `${apiUrl}/${resource}?pagesize=${perPage}&page=${page}&sortby=${field}&sortorder=${order}&filter=${filterString}`;
      return httpClient(finalUrl).then(({ headers, json }) => {
        return {
          data: json.result,
          total: json.total
        };
      });
    },
    getOne: (resource: any, params: any) => {
      const finalUrl = `${apiUrl}/${resource}/${params.id}`;
      return httpClient(finalUrl).then(({ headers, json }) => {
        return {
          data: json.result,
          total: json.total
        };
      });
    },
    getMany: (resource: any, params: any) => {
      var ids = params.ids;
      let where = '';
      if (ids.length == 1) {
        where = `mid=` + ids[0];
      } else {
        where = `mid=`;
        ids.forEach(function (id, index) {
          where += `${id},`;
        });
      }

      const finalUrl = `${apiUrl}/${resource}?${where}`;
      return httpClient(finalUrl).then(({ headers, json }) => {
        return {
          data: json.result,
          total: json.total
        };
      });
    },
    getManyReference: (resource: any, params: any) => {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const filter = params.filter;
      if (resource == 'meeting_participant') {
        delete filter['employee_id='];
      }

      if (params.target != undefined) {
        let col = params.target + '=';
        let val = params.id;
        filter[col] = val;
      }

      const filterString = JSON.stringify(filter);
      //debugger;

      // var ids = params.ids;
      // let where = "";
      // if (ids) {
      //     if (ids.length == 1) {
      //         where = `&mid=` + ids[0];
      //     } else {
      //         where = `&mid=`;
      //         ids.forEach(function (id, index) {
      //             where += `${id},`;
      //         });
      //     }
      // }
      //const finalUrl = `${apiUrl}/${resource}?pagesize=${perPage}&page=${page}&sortby=${field}&sortorder=${order}&filter=${filterString}${where}`;
      const finalUrl = `${apiUrl}/${resource}?pagesize=${perPage}&page=${page}&sortby=${field}&sortorder=${order}&filter=${filterString}`;

      return httpClient(finalUrl).then(({ headers, json }) => {
        return {
          data: json.result,
          total: json.total
        };
      });
    },
    create: async (resource: any, params: any) => {
      let data = params.data;


      if (data.hasOwnProperty('path')) {
        if (data.path != null && data.path.rawFile != null) {
          let response = await UploadFile('file', data.path);
          if (response.status === 'success') {
            data.path = response.result;
          } else {
            data.path = '';
          }
        }
      }

      if (data.hasOwnProperty('photo_url')) {
        if (data.photo_url != null && data.photo_url.rawFile != null) {
          let response = await UploadFile('file', data.photo_url);
          if (response.status === 'success') {
            data.photo_url = response.result;
          } else {
            data.photo_url = '';
          }
        }
      }

      const finalUrl = `${apiUrl}/${resource}`;
      return httpClient(finalUrl, {
        method: 'POST',
        body: JSON.stringify(data)
      }).then(({ headers, json }) => {
        return {
          data: json.result,
          total: json.total
        };
      });
    },
    update: async (resource: any, params: any) => {
      let data = params.data;

      if (data.hasOwnProperty('path')) {
        if (data.path != null && data.path.rawFile != null) {
          let response = await UploadFile('file', data.path);
          if (response.status === 'success') {
            data.path = response.result;
          } else {
            data.path = '';
          }
        }
      }
      if (data.hasOwnProperty('photo_url')) {
        if (data.photo_url != null && data.photo_url.rawFile != null) {
          let response = await UploadFile('file', data.photo_url);
          if (response.status === 'success') {
            data.photo_url = response.result;
          } else {
            data.photo_url = '';
          }
        }
      }
      
      const finalUrl = `${apiUrl}/${resource}/${params.id}`;
      return httpClient(finalUrl, {
        method: 'PUT',
        body: JSON.stringify(data)
      }).then(({ headers, json }) => {
        return {
          data: json.result,
          total: json.total
        };
      });
    },
    updateMany: (resource: any, params: any) => {
      const finalUrl = `${apiUrl}/${resource}`;
      return httpClient(finalUrl).then(({ headers, json }) => {
        return {
          data: json.result,
          total: 10
        };
      });
    },
    delete: (resource: any, params: any) => {
      const finalUrl = `${apiUrl}/${resource}/${params.id}`;
      return httpClient(finalUrl, {
        method: 'DELETE'
      }).then(({ headers, json }) => {
        return {
          data: json.result,
          total: json.total
        };
      });
    },
    deleteMany: (resource: any, params: any) => {
      const finalUrl = `${apiUrl}/${resource}`;
      return httpClient(finalUrl, {
        method: 'DELETE'
      }).then(({ headers, json }) => {
        return {
          data: json.result,
          total: 10
        };
      });
    }
  };
};
