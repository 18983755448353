import React from "react";

export const WebFooter = (props) => {
  return (
    <>
      <div className="container-fluid copyright bg-dark py-4">
        <div className="container text-center">
          <p className="mb-2">
            Copyright &copy;{" "}
            <a className="fw-semi-bold" href="#">
              Mapsko Casa Bella Owners Association
            </a>
            , All Right Reserved.
          </p>
        </div>
      </div>
    </>
  );
};
