import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button,
    Create, SimpleForm, TextInput, ReferenceInput, SelectInput,
    DateInput, BooleanInput, NumberInput, FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton, AutocompleteInput
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../common/apiClient";
import { containerStyles } from "../../common/formStyles";
import { makeStyles } from '@material-ui/core/styles';



const PostCreateToolbar = props => (

    <Toolbar {...props}>
        <SaveButton submitOnEnter={true} onSave={props.onSave} />
        <Button
            variant="contained" color="primary"
            label="Back" size="medium"
            startIcon={<CancelIcon />}
            style={{ marginLeft: "30px" }}
            onClick={props.onCancel}
        />
    </Toolbar>
);
const QuoteFacilities = props => {
    const classes = containerStyles({});
    let defaultValues = {
        quote_id: parseInt(props.id) ,
        remarks:"",
        qty:1
    };

    const handleSubmit = async (formdata) => {
        console.log(formdata);

        apiClient.addEntiyRecord("quote_facility", formdata).then(res => {
            console.log(res);
            props.onClose();
        }).catch(err => {
            console.log(err);
        });

    }
    return (
        <Create {...props}>
            <SimpleForm onSubmit={handleSubmit} initialValues={defaultValues}
                toolbar={<PostCreateToolbar onSave={handleSubmit} onCancel={props.onCancel} />} variant="outlined">

                <ReferenceInput perPage={200}  label="Facility" source="facility_id" reference="Service" fullWidth={true}
                    sort={{ field: 'name', order: 'asc' }}>
                    <SelectInput optionText="name" />
                </ReferenceInput>

                <NumberInput source="qty" label="Qty " fullWidth={true} validate={[required()]} />
                <RichTextInput source="remarks" label="Remarks" fullWidth={true}/>
            </SimpleForm>
        </Create>
    );
}
export default QuoteFacilities;