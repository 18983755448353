import React from 'react';
import { List, Datagrid, TextField, DateField, RichTextField, Button, EditButton } from 'react-admin';
import { useParams, useHistory } from "react-router-dom";

import AssignmentIcon from '@material-ui/icons/Assignment';

export const DisplayReportButton = (props) => {
    const onButtonClick = () => {
        props.onClick(props.record.id);
    }
    return (
        <Button
            color="secondary" 
            size="medium"
            redirect={false}
            onClick={onButtonClick}
            label="Report"
            startIcon={<AssignmentIcon />}
        />
    )
}

export const ActivityList = props => {
    let history = useHistory();
    return (
        <React.Fragment>
            <List title="List of Quotes" {...props} sort={{ field: 'id', order: 'DESC' }} bulkActionButtons={false}>
                <Datagrid rowClick={false} >
                    <EditButton/>
                    <TextField source="name" />
                    <DateField source="start_date" />
                    <DateField source="end_date" />
                    <DisplayReportButton label="Report" onClick={(id) => { history.push(`/quotereport/${id}`); }} />
                    <RichTextField source="description" />
                </Datagrid>
            </List>
        </React.Fragment>
    )
}