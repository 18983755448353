import React from 'react';
import { Edit, SimpleForm, TextInput, required, DateInput } from 'react-admin';
import { FormToolbar } from "../../common/FormToolbar";
import { BooleanInput } from 'react-admin';
import { ReferenceInput } from 'react-admin';
import { SelectInput } from 'react-admin';

export const ActivityEdit = props => {
    return (
        <React.Fragment>
            <Edit label="Edit Category" {...props}>
                <SimpleForm toolbar={<FormToolbar {...props} />}
                    variant="outlined">
                        
                    <TextInput source="name" fullWidth={true} validate={[required()]}/>
                   
                </SimpleForm>
            </Edit>
        </React.Fragment>
    );
}