import React from 'react';
import { Create, SimpleForm, TextInput, required, DateInput } from 'react-admin';
import { FormToolbar } from "../../common/FormToolbar";
import RichTextInput from 'ra-input-rich-text';

export const ActivityCreate = props => {
    return (
        <React.Fragment>
            <Create label="Edit Subject" {...props}>
                <SimpleForm redirect="list" toolbar={<FormToolbar {...props} />}
                    variant="outlined">
                    <TextInput source="name" fullWidth={true} validate={[required()]} />
                    <DateInput source="start_date" fullWidth={true} validate={[required()]} />
                    <DateInput source="end_date" fullWidth={true} validate={[required()]} />
                    <RichTextInput source="description" fullWidth={true} />
                </SimpleForm>
            </Create>
        </React.Fragment>
    );
}