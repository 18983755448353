import React, { useState } from 'react';

import "./style/bootstrap.min.css";
import "./style/style.css";
import carousel from "./images/carousel-1.jpg";
import about from "./images/about-1.jpg";
import about1 from "./images/about-2.jpg";
import { WebHeader } from './common/Header';
import { WebFooter } from './common/Footer';

import team1 from "./images/team-1.jpg";
import team2 from "./images/team-2.jpg";
import team3 from "./images/team-3.jpg";
import { useEffect } from "react";
import * as apiClient from "../common/apiClient";

export const WebTeam = (props) => {

    const [team, setTeam] = useState([]);
    const [loading, setLoading] = useState(false);


    const getAllTeam = () => {
        setLoading(true);
        apiClient.getAllTeam().then(res => {
            console.log(res);
            setTeam(res.result);
            setLoading(false);
        }).catch(err => {
            console.log(err);
            setLoading(false);
        });
    }

    useEffect(() => {
        getAllTeam();
    }, []);
    return (
        <>
            <WebHeader />
            <div className='page'>
                <div className="container-xxl py-5">
                    <div className="container">
                        <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '600px' }}>
                            <p className="fw-medium text-uppercase text-primary mb-2">Our Team</p>
                            <h1 className="display-5 mb-5">Team Members</h1>
                        </div>
                        <div className="row g-4">
                            {team && team.map((item: any, index) => {
                                return (<div className="col-lg-3 col-md-6 wow" data-wow-delay="0.1s">
                                    <div className="team-item">
                                        {item.photo_url && <img className="img-fluid" src={item.photo_url} alt="" />}
                                        <div className="d-flex">
                                            <div className="flex-shrink-0 btn-square bg-primary" style={{ width: '10px', height: '90px' }}>
                                                {/* <i className="fa fa-2x fa-share text-white"></i> */}
                                            </div>
                                            <div className="position-relative overflow-hidden bg-light d-flex flex-column justify-content-center w-100 ps-4" style={{ height: '90px' }}>
                                                <h5>{item.name}</h5>
                                                <span className="text-primary">{item.designation}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>)
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <WebFooter />
        </>
    );
};
