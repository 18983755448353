import React from 'react';
import { Edit, SimpleForm, TextInput, required, DateInput } from 'react-admin';
import { FormToolbar } from "../../common/FormToolbar";
import { BooleanInput } from 'react-admin';
import { ReferenceInput } from 'react-admin';
import { SelectInput } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
export const ActivityEdit = props => {
    return (
        <React.Fragment>
            <Edit label="Edit Fqacility" {...props}>
                <SimpleForm toolbar={<FormToolbar {...props} />}
                    variant="outlined">
                         <ReferenceInput label="Category" source="category_id" reference="category" fullWidth={true}
                        sort={{ field: 'name', order: 'asc' }}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <TextInput source="name" fullWidth={true} validate={[required()]}/>
                    <RichTextInput source="description" fullWidth={true}/>
                    <BooleanInput source="is_show_qty" fullWidth={true} />
                </SimpleForm>
            </Edit>
        </React.Fragment>
    );
}