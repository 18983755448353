import React from 'react';
import { ReferenceField } from 'react-admin';
import { BooleanField } from 'react-admin';
import { List, Datagrid, TextField, DateField, RichTextField } from 'react-admin';

export const ActivityList = props => {
    return (
        <React.Fragment>
            <List title="List of Facilities" {...props} sort={{ field: 'id', order: 'DESC' }} exporter={false} bulkActionButtons={false}>
                <Datagrid rowClick="edit" >
                    <TextField source="name" />
                    <TextField source="email" />
                    <TextField source="subject" />
                    <RichTextField source="message" />
                </Datagrid>
            </List>
        </React.Fragment>
    )
}