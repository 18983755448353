import React from 'react';

import "./style/bootstrap.min.css";
import "./style/style.css";
import carousel from "./images/carousel-1.jpg";
import about from "./images/about-1.jpg";
import about1 from "./images/about-2.jpg";
import { WebHeader } from './common/Header';
import { WebFooter } from './common/Footer';

export const WebHome = (props) => {

    return (
        <>
            <WebHeader />
            <div className='page'>
                <div className="container-fluid px-0 mb-5">
                    <div id="header-carousel" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img className="w-100" src={carousel} alt="Image" />
                                <div className="carousel-caption">
                                    <div className="container">
                                        <div className="row justify-content-center">
                                            <div className="col-lg-10 text-center">
                                                <h1 className="display-1 text-white mb-5 ">Mapsko Casa Bella Owners Association</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-xxl py-5">
                    <div className="container">
                        <div className="row g-5">
                            <div className="col-lg-6">
                                <div className="row gx-3 h-100">
                                    <div className="col-6 align-self-start  " data--delay="0.1s">
                                        <img className="img-fluid" src={about} />
                                    </div>
                                    <div className="col-6 align-self-end  " data--delay="0.1s">
                                        <img className="img-fluid" src={about1} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 " data--delay="0.5s">
                                <p className="fw-medium text-uppercase text-primary mb-2">Mapsko Casa Bella</p>
                                <p className="mb-4">Mapsko Casa Bella is a luxury society with apartments and villas in perfect combination of comfort and style at prime location in Sector 82, Gurgaon surrounded with Malls, Banks, Schools and everything important in vicinity.</p>
                                <h3 className="mb-4">Mapsko Casa Bella Owners Association</h3>
                                <p className="mb-4">For any residential community to become better, there is a need for a strong, dependable Owners Association. The main job of the Owners Association is to do everything possible that is a welfare to the residents and property owners. The current Mapsko Casa Bella Association (MCBOA) has a fantastic leadership & dynamic team and is truly working for excellent positive changes to the community. The contribution of the current Association management is poised to make Mapsko Casa Bella one of the better communities to live in New Gurgaon.</p>
                                {/* <div className="d-flex align-items-center mb-4">
                                    <div className="flex-shrink-0 bg-primary p-4">
                                        <h1 className="display-2">25</h1>
                                        <h5 className="text-white">Years of</h5>
                                        <h5 className="text-white">Experience</h5>
                                    </div>
                                    <div className="ms-4">
                                        <p><i className="fa fa-check text-primary me-2"></i>Power & Energy</p>
                                        <p><i className="fa fa-check text-primary me-2"></i>Civil Engineering</p>
                                        <p><i className="fa fa-check text-primary me-2"></i>Chemical Engineering</p>
                                        <p><i className="fa fa-check text-primary me-2"></i>Mechanical Engineering</p>
                                        <p className="mb-0"><i className="fa fa-check text-primary me-2"></i>Oil & Gas Engineering</p>
                                    </div>
                                </div> */}
                                <div className="row pt-2">
                                    <div className="col-sm-6">
                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0 btn-lg-square rounded-circle bg-primary">
                                                <i className="fa fa-envelope-open text-white"></i>
                                            </div>
                                            <div className="ms-3">
                                                <p className="mb-2">Email us</p>
                                                <h5 className="mb-0"><a href="mailto:mcboa82@gmail.com">mcboa82@gmail.com</a></h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0 btn-lg-square rounded-circle bg-primary">
                                                <i className="fa fa-phone-alt text-white"></i>
                                            </div>
                                            <div className="ms-3">
                                                <p className="mb-2">Call us</p>
                                                <h5 className="mb-0"><a href="tel:919910261081">+91-9910261081</a></h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <WebFooter />
        </>
    );
};
