import React, { useState, useEffect } from 'react';
import { useMediaQuery } from '@material-ui/core';

import * as apiClient from "../common/apiClient";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CountBlock from "./CountBlock";
import UpcomingAssignmentList from "./student/upcoming_assignment";
import Paper from '@material-ui/core/Paper';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AssignmentScholarList from './teacher/assignment_status';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import QuoteList from './quote_list';
import QuoteDetails from './quote_details';
import { useHistory } from 'react-router-dom';

export interface IHeader {
    SubjectCount: number;
    TestCount: number;
    QuestionCount: number;
    CandidateCount: number;
}

const useStyles = makeStyles({
    flexCenter: { display: 'flex', justifyContent: "center", alignContent: "center" },
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
    root: {
        width: '100%',
    },
    heading: {
        fontSize: 20,
        fontWeight: 'bold',
    },
});

const Dashboard = () => {
    const [role, setRole] = useState("Student");
    const [assignments, setAssignments] = useState([]);
    const [quotesData, setQuotesData] = useState([]);
    const [categories, setCategories] = useState<any[]>([]);
    const [quotesDetails, setQuotesDetails] = useState([]);
    const [selectedQuote, setSelectedQuote] = useState(null);
    const [userData, setUserData] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
   
    
    let history = useHistory();

    const styles = useStyles({});


    const quotes_for_today = () => {
        setLoading(true);
        apiClient.quotes_for_today().then(res => {
            //console.log(res);
            setQuotesData(res.result);
            setLoading(false);
        }).catch(err => {
            console.log(err);
            setLoading(false);
        });
    }

    const createQuoteDetailsView = (data) => {
        var outObject = data.reduce(function (a, e) {
            let estKey = (e['category_name']);
            (a[estKey] ? a[estKey] : (a[estKey] = null || [])).push(e);
            return a;
        }, {});
        var keys = Object.keys(outObject);
        setCategories(keys);
        setQuotesDetails(outObject);
    }


    const quote_details = (item) => {
        setLoading(true);
        apiClient.quote_details(item.id).then(res => {
            //console.log(res);
            createQuoteDetailsView(res.result);
            setLoading(false);
            setSelectedQuote(item);
        }).catch(err => {
            console.log(err);
            setLoading(false);
        });
    }

    const onSubmitBid = () => {
        setCategories([]);
        setQuotesDetails([]);
        setSelectedQuote(null);
    }


    useEffect(() => {
        let role = localStorage.getItem("role");
        let userid = localStorage.getItem("userid");

        if (!userid || userid == "") {
            history.push("/register");
            return;
        }
        if (role == "company") {
            history.push("/dashboard");
            return;
        }
  

        setRole(role!);
        quotes_for_today();
    }, []);

    return (
        <div style={{ paddingTop: 20, }}>
            
            {(role == "company") &&
                <div className={styles.flexColumn}>
                    <Paper elevation={3} >
                        <h2 style={{ textAlign: "center" }}>Open Tenders</h2>
                        {(role == "company") && quotesData && <QuoteList data={quotesData} onQuoteClick={(item) => { quote_details(item); }} isLoggedIn={true} />}
                    </Paper>
                </div>

            }
            {(role == "company") &&
                <div className={styles.flexColumn}>
                    <Paper elevation={3} >
                        {categories && categories.length > 0 && <QuoteDetails isLoggedIn={true} onGoBack={onSubmitBid} onSubmitBid={onSubmitBid} keys={categories} data={quotesDetails} quote={selectedQuote} />}
                    </Paper>
                </div>

            }
        </div>
    );
}

export default Dashboard;
