import React from 'react';
import { ReferenceField } from 'react-admin';
import { BooleanField } from 'react-admin';
import { List, Datagrid, TextField, DateField } from 'react-admin';

export const ActivityList = props => {
    return (
        <React.Fragment>
            <List title="List of Categories" {...props} sort={{ field: 'id', order: 'DESC' }} exporter={false} bulkActionButtons={false}>
                <Datagrid rowClick="edit" >
                    <ReferenceField source="doc_group_id" reference="docgroup" label="Group" link={false}>
                        <TextField source="name" />
                    </ReferenceField>
                    <TextField source="name" />
                    <TextField source="path" />
                </Datagrid>
            </List>
        </React.Fragment>
    )
}