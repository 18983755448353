import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import "../style/bootstrap.min.css";
import "../style/style.css";

export const WebHeader = (props) => {
  let history = useHistory();
  const [name, setName] = useState<any>("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showNavbar, setShowNavbar] = useState(false);


  let navClass = "collapse navbar-collapse";
  if (showNavbar) {
    navClass = "collapse navbar-collapse show";
  }

  useEffect(() => {
    let userid = localStorage.getItem("userid");
    if (!userid || userid == "") {
      setIsLoggedIn(false);
    } else setIsLoggedIn(true);

    let displayName = localStorage.getItem("displayName");
    setName(displayName);
  }, []);

  return (
    <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top py-0 pe-5">
      <a href="index.html" className="navbar-brand ps-5 me-0">
        <h1 className="text-white m-0 logo-text">MCBOA</h1>
      </a>
      <button
        type="button"
        className="navbar-toggler me-0"
        data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse"
        onClick={() => { setShowNavbar(!showNavbar); }}
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className={navClass} id="navbarCollapse">
        <div className="navbar-nav ms-auto p-4 p-lg-0">
          <a href="#home" className="nav-item nav-link">
            Home
          </a>
          <a href="#team" className="nav-item nav-link">
            Our Team
          </a>
          <a href="#contact" className="nav-item nav-link">
            Contact
          </a>
          <a href="#documentlist" className="nav-item nav-link">
            Documents
          </a>
          {
            <a href="#tenders" className="nav-item nav-link">
              Tenders
            </a>
          }

          {!isLoggedIn && (
            <a
              href="#register"
              className="nav-item nav-link"
            >
              Login / Register
            </a>
          )}
          {isLoggedIn && (
            <div
              className="nav-item nav-link"
              onClick={() => {
                localStorage.setItem("userid", "");
                localStorage.setItem("username", "");
                localStorage.setItem("password", "");
                localStorage.setItem("displayName", "");
                localStorage.setItem("token", "");
                localStorage.setItem("role", "");
                localStorage.setItem("reference_id", "");
                localStorage.setItem("designation", "");
                setIsLoggedIn(false);
                setName("");
                history.push("/register");
              }}
            >
              Logout
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};
