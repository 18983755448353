import React from 'react';
import { Create, SimpleForm, TextInput, required, DateInput, ReferenceInput, SelectInput, FileInput, FileField } from 'react-admin';
import { FormToolbar } from "../../common/FormToolbar";
import { BooleanInput } from 'react-admin';
import { ImageInput } from 'react-admin';
import { ImageField } from 'react-admin';
import { NumberInput } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

export const ActivityCreate = props => {
    let defaultValues = {
        is_active: true,
    };
    return (
        <React.Fragment>
            <Create label="Add Team Member" {...props} undoable={false}>
                <SimpleForm redirect="list" initialValues={defaultValues}
                    toolbar={<FormToolbar {...props} />}
                    variant="outlined">

                    <TextInput source="name" fullWidth={true} validate={[required()]} />
                    <TextInput source="designation" fullWidth={true} validate={[required()]} />
                    <RichTextInput source="description" fullWidth={true} />
                    <ImageInput source="photo_url" label="Photo" accept="image/*" className="logourl">
                        <ImageField source="photo_url" title="Photo" />
                    </ImageInput>
                    <NumberInput source="sort_order" fullWidth={true} validate={[required()]}/>
                    <BooleanInput source="is_active"/>
                </SimpleForm>
            </Create>
        </React.Fragment>
    );
}