import React, { useState, useEffect } from 'react';
import { useMediaQuery } from '@material-ui/core';

import * as apiClient from "../common/apiClient";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import QuoteList from '../dashboard/quote_list';
import QuoteDetails from '../dashboard/quote_details';
import { WebHeader } from './common/Header';
import { WebFooter } from './common/Footer';
import DocumentList from './Document_List';


const useStyles = makeStyles({
    flexCenter: { display: 'flex', justifyContent: "center", alignContent: "center" },
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
    root: {
        width: '100%',
    },
    heading: {
        fontSize: 20,
        fontWeight: 'bold',
    },
});

const WebDocument = () => {

    const [documents, setDocuments] = useState([]);
    const [documentGroups, setDocumentGroups] = useState([]);
    const [currentDocGroup, setCurrentDocGroup] = useState(0);
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState<any>("");
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const styles = useStyles({});

    const getDocuments = (id) => {
        setLoading(true);
        apiClient.getAllDocumentForDocGroup(id).then(res => {
            setCurrentDocGroup(id);
            setDocuments(res.result);
            setLoading(false);
        }).catch(err => {
            console.log(err);
            setLoading(false);
        });
    }

    const getAllDocumentGroups = () => {
        setLoading(true);
        apiClient.getAllDocumentGroups().then(res => {
            setDocumentGroups(res.result);
            setLoading(false);
        }).catch(err => {
            console.log(err);
            setLoading(false);
        });
    }




    useEffect(() => {
        //getDocuments();
        getAllDocumentGroups();
    }, []);

    return (
        <>
            <WebHeader />
            <div className='page' style={{ padding: 20, }}>

                <div className={styles.flexColumn}>
                    <Paper elevation={3} >
                        <h2 style={{ textAlign: "center" }}>Documents</h2>
                        {currentDocGroup == 0 && documentGroups && documentGroups.length > 0 && <DocumentList type="category" data={documentGroups} onClick={(id) => { getDocuments(id); }} />}
                        {currentDocGroup != 0 && documents && documents.length > 0 && <DocumentList type="document" data={documents} onClick={(id) => { }} />}
                        {currentDocGroup != 0 && <Button variant="contained" style={{ margin: "10px" }} onClick={() => {setCurrentDocGroup(0); }}
                            color="secondary">
                            Back
                        </Button>}
                        {!loading && documentGroups && documentGroups.length == 0 && <div style={{ textAlign: "center" }}>No Documents yet</div>}
                        {loading && <div style={{ textAlign: "center" }}><CircularProgress size={30} thickness={8} /></div>}
                    </Paper>
                </div>
            </div>
            <WebFooter />
        </>
    );
}

export default WebDocument;
