import React from 'react';
import { ImageField } from 'react-admin';
import { NumberField } from 'react-admin';
import { ReferenceField } from 'react-admin';
import { BooleanField } from 'react-admin';
import { List, Datagrid, TextField, DateField, RichTextField } from 'react-admin';

export const ActivityList = props => {
    return (
        <React.Fragment>
            <List title="List of Categories" {...props} sort={{ field: 'id', order: 'DESC' }} exporter={false} bulkActionButtons={false}>
                <Datagrid rowClick="edit" >
                    <TextField source="name" />
                    <TextField source="designation" />
                    <RichTextField source="description" />
                    <ImageField source="photo_url" label="Photo" />
                    <NumberField source="sort_order" />
                    <BooleanField source="is_active" />
                </Datagrid>
            </List>
        </React.Fragment>
    )
}